<template>
  <div class="page">
    <div class="title">套餐计划</div>
    <div class="main">
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">当前套餐</span>
        </div>
        <div class="n2">
          <div class="item">
            <div class="itk">版本</div>
            <div class="itv" v-if="isvip">来搜会员版本</div>
            <div class="itv" v-else>来搜免费版本</div>
          </div>
          <div class="item">
            <div class="itk">服务时长</div>
            <div class="itv action" v-if="isvip">{{ viptime }}</div>
            <div class="itv action" v-else>不限</div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="n1">
          <div class="hlhs pbgcolor"></div>
          <span class="txt">购买套餐</span>
        </div>
        <div class="fixtitle">
          <div class="item"></div>
          <div class="item">
            <div class="tit">免费版</div>
            <div class="price1">￥0元</div>
            <div class="btn">
              <button class="pcolor btn1">立即体验</button>
            </div>
          </div>
          <div class="item">
            <div class="tit pcolor">付费版</div>
            <div class="price1">￥99/月</div>
            <div class="price2">
              <span class="pcolor">￥990/年</span>
              <del class="del">1188/年</del>
            </div>
            <div class="btn">
              <button class="pbgcolor btn2" @click="goPage('buyVip')">立即购买</button>
            </div>
          </div>
        </div>
        <div class="info">
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">批量查排名</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">导出历史排名记录</div>
              <div class="i2">{{ tcjh.expHisRank.general }}次/月</div>
              <div class="i2">{{ tcjh.expHisRank.vip }}次/月</div>
            </div>
            <div class="item">
              <div class="i1">导出关注关键词</div>
              <div class="i2">{{ tcjh.expKeys.general }}次/月</div>
              <div class="i2">{{ tcjh.expKeys.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">商品搜索分析</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">最大加载页数</div>
              <div class="i2">{{ tcjh.autoPageGdShPage.general }}页</div>
              <div class="i2">{{ tcjh.autoPageGdShPage.vip }}页</div>
            </div>
            <div class="item">
              <div class="i1">一键分析</div>
              <div class="i2">{{ tcjh.fenxiGdSh.general }}次/月</div>
              <div class="i2">{{ tcjh.fenxiGdSh.vip }}次/月</div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expGdSh.general }}次/月</div>
              <div class="i2">{{ tcjh.expGdSh.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">店铺搜索分析</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">最大加载页数</div>
              <div class="i2">{{ tcjh.autoSpShPage.general }}页</div>
              <div class="i2">{{ tcjh.autoSpShPage.vip }}页</div>
            </div>
            <div class="item">
              <div class="i1">一键分析</div>
              <div class="i2">{{ tcjh.fenxiSpSh.general }}次/月</div>
              <div class="i2">{{ tcjh.fenxiSpSh.vip }}次/月</div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expSpSh.general }}次/月</div>
              <div class="i2">{{ tcjh.expSpSh.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">商品分析</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expGdFx.general }}次/月</div>
              <div class="i2">{{ tcjh.expGdFx.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">店铺分析</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">查看店铺商品数据</div>
              <div class="i2">{{ tcjh.spGd.general }}页</div>
              <div class="i2">{{ tcjh.spGd.vip }}页</div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expSpFx.general }}次/月</div>
              <div class="i2">{{ tcjh.expSpFx.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">商机订阅</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">设置关键词</div>
              <div class="i2">{{ tcjh.rfqKw.general }}个</div>
              <div class="i2">{{ tcjh.rfqKw.vip }}个</div>
            </div>
            <div class="item">
              <div class="i1">设置类目</div>
              <div class="i2">{{ tcjh.rfqCtg.general }}个</div>
              <div class="i2">{{ tcjh.rfqCtg.vip }}个</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">实时商机抢报</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">最大加载页数</div>
              <div class="i2">{{ tcjh.autoPageRfq.general }}页</div>
              <div class="i2">{{ tcjh.autoPageRfq.vip }}页</div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expRfq.general }}次/月</div>
              <div class="i2">{{ tcjh.expRfq.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">访客分析</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">同步周期</div>
              <div class="i2">{{ tcjh.syncCycle.general }}天</div>
              <div class="i2">{{ tcjh.syncCycle.vip }}天</div>
            </div>
            <div class="item">
              <div class="i1">导出数据</div>
              <div class="i2">{{ tcjh.expFkFx.general }}次/月</div>
              <div class="i2">{{ tcjh.expFkFx.vip }}次/月</div>
            </div>
            <div class="item">
              <div class="i1">同步明细并分析</div>
              <div class="i2">{{ tcjh.syncDescFx.general }}次/月</div>
              <div class="i2">{{ tcjh.syncDescFx.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">商品信息快速预览</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">关键词展示</div>
              <div class="i2">支持</div>
              <div class="i2">支持</div>
            </div>
            <div class="item">
              <div class="i1">找货源次数</div>
              <div class="i2">{{ tcjh.sourceGood.general }}次/月</div>
              <div class="i2">{{ tcjh.sourceGood.vip }}次/月</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">关键词中心</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">收藏关键词</div>
              <div class="i2">{{ tcjh.favKw.general }}个</div>
              <div class="i2">{{ tcjh.favKw.vip }}个</div>
            </div>
            <div class="item">
              <div class="i1">单次找词数量</div>
              <div class="i2">每次最多{{ tcjh.onceKw.general }}个</div>
              <div class="i2">每次最多{{ tcjh.onceKw.vip }}个</div>
            </div>
          </div>
          <h1></h1>
          <div class="list">
            <div class="item">
              <div class="i1 txtbor">商品收藏</div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
              <div class="i2">
                <svg viewBox="64 64 896 896" focusable="false" class="pcolor" data-icon="check" width="1em" height="1em"
                  fill="currentColor" aria-hidden="true">
                  <path
                    d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                  </path>
                </svg>
              </div>
            </div>
            <div class="item">
              <div class="i1">收藏商品数量</div>
              <div class="i2">{{ tcjh.favGood.general }}个</div>
              <div class="i2">{{ tcjh.favGood.vip }}个</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isvip: false,
      viptime: '',
      /*expHisRank        导出历史排名记录
expKeys            导出关注关键词
autoPageGdShPage      最大加载页数
fenxiGdSh                        一键分析
expGdSh


店铺搜索分析
autoSpShPage       一行加载页数
fenxiSpSh                一件分析
expSpSh                导出数据

商品分析
expGdFx            导出数据

店铺分析
spGd          查看店铺商品数据
expSpFx            导出数据

商机订阅
rfqKw        设置关键词
rfqCtg        设置类目

实时商机抢报
autoPageRfq            一次加载页数
expRfq                    导出数据

访客分析
syncCycle            同步周期
expFkFx                导出访客分析
syncDescFx        同步明细并分析

商品信息快速预览
showKw    关键词展示
sourceGood            找货源次数
关键词中心
favKw   收藏关键词
onceKw        单词找词数量

商品收藏
favGood   收藏商品数量     */
      tcjh: {
        expHisRank: {
          general: null,
          vip: null,
        },
        expKeys: {
          general: null,
          vip: null,
        },
        autoPageGdShPage: {
          general: null,
          vip: null,
        },
        fenxiGdSh: {
          general: null,
          vip: null,
        },
        expGdSh: {
          general: null,
          vip: null,
        },
        autoSpShPage: {
          general: null,
          vip: null,
        },
        fenxiSpSh: {
          general: null,
          vip: null,
        },
        expSpSh: {
          general: null,
          vip: null,
        },
        expGdFx: {
          general: null,
          vip: null,
        },
        spGd: {
          general: null,
          vip: null,
        },
        expSpFx: {
          general: null,
          vip: null,
        },
        rfqKw: {
          general: null,
          vip: null,
        },
        rfqCtg: {
          general: null,
          vip: null,
        },
        autoPageRfq: {
          general: null,
          vip: null,
        },
        expRfq: {
          general: null,
          vip: null,
        },
        syncCycle: {
          general: null,
          vip: null,
        },
        expFkFx: {
          general: null,
          vip: null,
        },
        syncDescFx: {
          general: null,
          vip: null,
        },
        showKw: {
          general: null,
          vip: null,
        },
        sourceGood: {
          general: null,
          vip: null,
        },
        favKw: {
          general: null,
          vip: null,
        },
        onceKw: {
          general: null,
          vip: null,
        },
        favGood: {
          general: null,
          vip: null,
        },

      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    goPage(page) {
      // 暂不弹出
      return
      window.open('/#/' + page, '_blank')

    },
    init(p) {
      // 获取套餐计划
      this.axios.get('/index/public/getplanning').then(data => {
        console.info('data == ', data)
        data.data.forEach(item => {
          this.tcjh[item.pkey] = item
        })
      })
      this.axios.get('/users/getuserinfo').then(res => {
        let userinfo = res.data
        this.isvip = userinfo.vipstate === 0;
        this.viptime = userinfo.viptime;
        sessionStorage.setItem('userinfo', JSON.stringify(userinfo));

      })

    },
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  margin-top: 30px;
  padding: 20px;
  color: #666;
}

.box .n1 {
  display: flex;
  align-items: center;
}

.box .n1 .hlhs {
  width: 2px;
  height: 16px;
}

.box .n1 .txt {
  margin-left: 10px;
}

.box .n2 .item {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 20px;
}

.box .n2 .item .itv {
  margin-left: 20px;
}

.fixtitle {
  height: 200px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: -20px;
  background-color: #ffffff;
  z-index: 10;
}

.fixtitle .item {
  width: 260px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fixtitle .item .tit {
  font-size: 18px;
  font-weight: bold;
}

.fixtitle .item .price1 {
  margin: 10px 0;
}

.fixtitle .item .price2 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 700;
}

.fixtitle .item .price2 .del {
  margin-left: 10px;
  font-size: 16px;
  color: #999;
}

.fixtitle .item .btn {
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-bottom: 10px;
}

.fixtitle .item .btn button {
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  border-radius: 8px;
}

.fixtitle .item .btn .btn1 {
  background-color: #fff;
  border: 2px solid #FF6A00;
}

.fixtitle .item .btn .btn2 {
  color: #fff;
  border: 1px solid #fff;
}

.fixtitle .item .btn button:hover {
  cursor: pointer;
}

.fixtitle .item .btn .btn1:active {
  border: 2px solid #CF6A00;
}

.fixtitle .item .btn .btn2:active {
  color: #eee;
}

.info {
  margin: 0 auto;
}

.info .list .item {
  display: flex;
  justify-content: center;
}

h1 {
  margin: 20px auto;
  height: 1px;
  max-width: 760px;
  background-color: #eee;
}

.info .list .item .i1 {
  width: 260px;
  padding: 5px 20px;
  display: flex;
  font-size: 14px;
}

.info .list .item .i1.txtbor {
  font-weight: 700;
  font-size: 15px;
}

.info .list .item .i2 {
  width: 260px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
</style>
